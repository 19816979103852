import React, { useState, useEffect, useRef } from "react";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { CircularProgress } from "@mui/material";

const ffmpeg = createFFmpeg({ log: false });

const FrameExtractor = ({ videoUrl, onFramesExtracted, onFrameSelected }) => {
  const [frames, setFrames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFrame, setSelectedFrame] = useState(null);
  const [videoBlobUrl, setVideoBlobUrl] = useState(null);
  const isFFmpegBusy = useRef(false);

  const extractFrames = async (videoUrl) => {
    console.log("extractFrames videoURL :", videoUrl);
    if (isFFmpegBusy.current) {
      console.warn("FFmpeg is already processing. Please wait.");
      return;
    }

    isFFmpegBusy.current = true;
    setLoading(true);
    try {
      if (!ffmpeg.isLoaded()) {
        await ffmpeg.load();
      }

      let videoBlobUrl = "";

      if (typeof videoUrl === "string") {
        if (videoUrl.startsWith("blob:") || videoUrl.startsWith("http")) {
          videoBlobUrl = videoUrl;
        } else {
          throw new Error(`Invalid video URL: ${videoUrl}`);
        }
      } else if (videoUrl instanceof Blob || videoUrl instanceof File) {
        videoBlobUrl = URL.createObjectURL(videoUrl);
        setVideoBlobUrl(videoBlobUrl);
      } else if (typeof videoUrl === "object" && videoUrl.url) {
        videoBlobUrl = videoUrl.url;
      } else {
        throw new Error(
          `Invalid video URL or Blob input. Received type: ${typeof videoUrl}`
        );
      }

      // Fetch the video data
      const response = await fetch(videoBlobUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.blob();
      ffmpeg.FS("writeFile", "input.mp4", await fetchFile(data));

      // Create a video element to extract metadata (duration)
      const videoElement = document.createElement("video");
      videoElement.src = videoBlobUrl;

      return new Promise((resolve, reject) => {
        videoElement.onloadedmetadata = async () => {
          if (isNaN(videoElement.duration)) {
            reject("Invalid video duration");
            return;
          }

          const videoDuration = Math.min(videoElement.duration, 60);
          const frameRate = 5;
          const totalFrames = Math.floor(videoDuration * frameRate);

          const selectedIndices = new Set();
          while (selectedIndices.size < 5) {
            selectedIndices.add(Math.floor(Math.random() * totalFrames));
          }

          const filter = `select='eq(n\,${Array.from(selectedIndices).join(
            ")+eq(n,"
          )})'`;

          try {
            await ffmpeg.run(
              "-i",
              "input.mp4",
              "-vf",
              filter,
              "-vsync",
              "vfr",
              "frame_%03d.jpg"
            );

            // Clean up previous frame URLs
            frames.forEach((frameUrl) => URL.revokeObjectURL(frameUrl));

            const frameFiles = ffmpeg
              .FS("readdir", "/")
              .filter((file) => file.startsWith("frame_"));
            const frameUrls = frameFiles.map((file) => {
              const frameData = ffmpeg.FS("readFile", file);
              const frameBlob = new Blob([frameData.buffer], {
                type: "image/jpeg",
              });
              return URL.createObjectURL(frameBlob);
            });

            setFrames(frameUrls);
            setSelectedFrame(frameUrls[0]);
            onFrameSelected(frameUrls[0]);
            onFramesExtracted(frameUrls);
            resolve();
          } catch (error) {
            reject(`Error running FFmpeg command: ${error.message}`);
          } finally {
            isFFmpegBusy.current = false;
          }
        };

        videoElement.onerror = (e) => {
          reject(`Error loading video metadata: ${e.message}`);
        };

        videoElement.load();
      });
    } catch (error) {
      console.error("Error extracting frames:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(videoUrl);
    if (videoUrl && !isFFmpegBusy.current) {
      setFrames([]);
      extractFrames(videoUrl);
    }

    return () => {
      if (videoBlobUrl) {
        URL.revokeObjectURL(videoBlobUrl);
      }
      frames.forEach((frameUrl) => {
        URL.revokeObjectURL(frameUrl);
      });
    };
  }, [videoUrl]);

  const handleFrameClick = (frameUrl) => {
    setSelectedFrame(frameUrl);
    onFrameSelected(frameUrl);
  };

  return (
    <div style={{ display: "flex", overflowX: "auto", marginTop: "5px" }}>
      {loading && <CircularProgress size={20} />}
      {!loading &&
        frames.map((frame, index) => (
          <img
            key={index}
            src={frame}
            alt={`Frame ${index}`}
            onClick={() => handleFrameClick(frame)}
            style={{
              width: "123px",
              margin: "0 5px",
              cursor: "pointer",
              borderRadius: "8px",
              border: selectedFrame === frame ? "3px solid #E86747" : "none",
            }}
          />
        ))}
    </div>
  );
};

export default FrameExtractor;
