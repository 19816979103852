import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import CategoriesSelection from "../../../components/category-selection/CategoriesSelection.jsx";
import CardGrid from "../../../components/card-grid/CardGrid";
import style from "./userBoard.module.scss";
import { Context } from "../../../state/UserContext.jsx";
import { CircularProgress } from "@mui/material";
import { useUser } from "../../../state/UserContext.jsx";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { NoData } from "../../../assets/images/index.js";
import { Helmet } from "react-helmet-async";
import debounce from "lodash/debounce";

const UserBoard = () => {
  const userData = JSON.parse(window.localStorage.getItem("user"));
  const [categoriesChecked, setCategoriesChecked] = useState(["All"]);
  const [checkedVideos, setCheckedVideos] = useState([]);
  const location = useLocation();
  const cachedData = sessionStorage.getItem("videosData");
  const initialVideos = cachedData ? JSON.parse(cachedData) : { videos: [] };
  const [allVideos, setAllVideos] = useState(initialVideos.videos || []);
  const { setIsSearching, isSearching, displayVideos, setDisplayVideos } =
    useContext(Context);

  const [displayedVideos, setDisplayedVideos] = useState([]);
  const [filter, setFilter] = useState("");
  const [productList, setProductList] = useState([]);
  const navigate = useNavigate();
  const [videoTypes, setVideosTypes] = useState([]);
  const [choosenType, setChoosenType] = useState("All");
  const { setUser } = useUser();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const observer = useRef();
  const videosPerLoad = 9;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const myParam = searchParams.get("data");

    localStorage.removeItem("videoBase64");
    localStorage.setItem("hasReloaded", "false");
    if (myParam) {
      const { user, token } = JSON.parse(myParam);
      setUser(user);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      localStorage.setItem("isBusinessOwner", false);
      localStorage.setItem("activeItem", "Explore");
      navigate("/");
    } else if (localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  const getAllVideos = async (page = 1, limit = videosPerLoad) => {
    try {
      const categoryParam = encodeURIComponent(categoriesChecked.join(","));

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/videos/all-videos?page=${page}&limit=${limit}&categories=${categoryParam}`
      );
      if (!res.ok) throw new Error("Network response was not ok");
      const response = await res.json();

      setTotalPages(response.totalPages);

      return Array.isArray(response.videos) ? response : { videos: [] };
    } catch (error) {
      console.error("Error fetching videos:", error);
      return { videos: [] };
    }
  };

  const filterVideos = (videos) => {
    const filtered = categoriesChecked.includes("All")
      ? videos
      : videos.filter((video) =>
          video.product.categories.some((category) =>
            categoriesChecked.includes(category)
          )
        );
    return filtered;
  };

  useEffect(() => {
    setCurrentPage(1);
    setHasMore(true);
    setAllVideos([]);
  }, [categoriesChecked]);

  const loadMoreVideos = useCallback(
    debounce(async () => {
      if (
        isLoadingMore ||
        !hasMore ||
        currentPage >= totalPages ||
        displayedVideos.length < videosPerLoad
      ) {
        console.log("No more videos to load or still loading.");
        return;
      }
      setIsLoadingMore(true);

      try {
        const nextPage = currentPage + 1;
        const response = await getAllVideos(nextPage, videosPerLoad);

        if (Array.isArray(response.videos)) {
          setAllVideos((prevVideos) => [...prevVideos, ...response.videos]);
          setDisplayedVideos((prevDisplay) => {
            if (!Array.isArray(prevDisplay)) {
              return response.videos;
            }
            return [...prevDisplay, ...response.videos];
          });
          setCurrentPage(nextPage);
          setHasMore(nextPage < response.totalPages);
        } else {
          console.error("Response videos is not an array:", response.videos);
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error loading more videos:", error);
        setHasMore(false);
      } finally {
        setIsLoadingMore(false);
      }
    }, 100),
    [isLoadingMore, hasMore, currentPage, totalPages, categoriesChecked]
  );

  const lastVideoElementRef = useCallback(
    (node) => {
      if (
        isLoadingMore ||
        !hasMore ||
        currentPage >= totalPages ||
        displayedVideos.length < videosPerLoad
      ) {
        if (observer.current) observer.current.disconnect();

        return;
      }

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            loadMoreVideos();
          }
        },
        {
          threshold: 0.1,
        }
      );

      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoadingMore, hasMore, currentPage, totalPages, categoriesChecked]
  );

  useEffect(() => {
    if (!isLoading && allVideos.length > 0) {
      const node = document.querySelector(".last-video-element");
      if (node) lastVideoElementRef(node);
    }
  }, [isLoading, allVideos, lastVideoElementRef]);

  useEffect(() => {
    const fetchVideos = async () => {
      setIsLoading(true);

      if (
        isSearching &&
        Array.isArray(displayVideos.videos) &&
        displayVideos.videos.length > 0
      ) {
        const filteredSearchResults = filterVideos(displayVideos.videos);
        setTotalPages(displayVideos.totalPages);

        let types = filteredSearchResults.map((video) => video.category);
        types = [...new Set(types)];
        setVideosTypes(["All", ...types]);

        const filteredVideosByType =
          choosenType && choosenType !== "All"
            ? filteredSearchResults.filter(
                (video) => video.category === choosenType
              )
            : filteredSearchResults;

        setDisplayedVideos(filteredVideosByType.slice(0, videosPerLoad));
      } else {
        // setIsSearching(false);
        setDisplayedVideos([]);

        const currentTime = new Date().getTime();
        const cachedData = sessionStorage.getItem("videosData");
        const cachedTime = sessionStorage.getItem("videosDataTime");

        // Log the cached data

        // if (cachedData && cachedTime && currentTime - cachedTime < 3600 * 1000) {
        //   const parsedData = JSON.parse(cachedData);
        //   console.log("Using cached videos", parsedData.videos);
        //   setAllVideos(parsedData.videos);

        //   const filteredVideos = filterVideos(parsedData.videos);
        //   console.log("Filtered Videos", filteredVideos);
        //   setDisplayVideos(filteredVideos.slice(0, videosPerLoad));
        // } else {
        const response = await getAllVideos(1, videosPerLoad);
        let types = response.videos.map((video) => video.category);
        types = [...new Set(types)];
        setVideosTypes(["All", ...types]);

        if (Array.isArray(response.videos)) {
          const filteredVideos = filterVideos(response.videos);

          const filteredVideosByType =
            choosenType && choosenType !== "All"
              ? filteredVideos.filter((video) => video.category === choosenType)
              : filteredVideos;

          setAllVideos(response.videos);
          setDisplayedVideos(filteredVideosByType.slice(0, videosPerLoad));
        } else {
          console.error("Expected videos to be an array:", response.videos);
        }
      }
      setIsLoading(false);
    };

    fetchVideos();
  }, [categoriesChecked, isSearching, displayVideos, choosenType]);

  // const filterVideoByType = () => {
  //   const videosByType = checkedVideos.filter(
  //     (video) => video.category === choosenType
  //   );
  //   return videosByType;
  // };

  // useEffect(() => {
  //   const videosByType = filterVideoByType();
  //   setDisplayVideos(videosByType.slice(0, 12));
  // }, [choosenType]);

  const LoadingIndicator = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "80px",
      }}
    >
      <CircularProgress />
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Recolyse | Explore Next-Generation Startup Products</title>
        <meta
          name="description"
          content="Discover innovative startup products showcased through Recolyse’s AI-driven platform. Explore demos and find the perfect solution for your needs.
          "
        />
      </Helmet>
      <hr style={{ margin: "5px 20px 0 20px" }} />
      <div className={style["explore"]}>
        <CategoriesSelection
          style={{ flex: "0 0 auto" }}
          categoriesChecked={categoriesChecked}
          setCategoriesChecked={setCategoriesChecked}
          filter={filter}
          setFilter={setFilter}
          choosenType={choosenType}
          setChoosenType={setChoosenType}
          videoTypes={videoTypes}
        />
        {isLoading ? (
          <LoadingIndicator />
        ) : displayedVideos?.length > 0 ? (
          <div className={style["content"]}>
            <div className={style["video-container"]}>
              <CardGrid
                videosData={displayedVideos}
                lastVideoElementRef={lastVideoElementRef}
              />
            </div>
            {isLoadingMore && <LoadingIndicator />}
          </div>
        ) : (
          !isLoading &&
          displayVideos.length === 0 && (
            <div className={style["no-data"]}>
              <img src={NoData} />
              <p>No Videos Found</p>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default UserBoard;
