import React, { useEffect, useState, useRef } from "react";
import styles from "./card.module.scss";
import { useUser } from "../../state/UserContext";
import {
  robo,
  redHeart,
  grayHeart,
  sound_icon,
  sound_off_icon,
  logo,
} from "../../assets/images";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress, debounce } from "@mui/material";
import Hls from "hls.js";

const Card = (props) => {
  const {
    openVideoDisplay,
    index,
    productName,
    videoPreview,
    productImage,
    videoCapture,
    textPreview,
    productId,
    videoId,
    isMuted,
    toggleMute,
    lastVideoElementRef,
    date,
    videoLikes,
    setCurrentPlayingVideo,
    currentPlayingVideo,
  } = props;

  const [likes, setLikes] = useState(videoLikes || []);
  const { plan, dateOfPost, productOwner } = textPreview;
  const { userData } = useUser();
  const video = useRef(null);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [duration, setDuration] = useState(null);
  const [hls, setHls] = useState(null);
  const [isVideoEnded, setIsVideoEnded] = useState(false);

  const [bufferedPercentage, setBufferedPercentage] = useState(0);
  const [playedPercentage, setPlayedPercentage] = useState(0);

  const fetchPost = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/posts/${videoId}`
      );
      if (response) return response.data;
    } catch (error) {
      return null;
    }
  };

  const handleLike= async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/videos/Mobile_IOS/likeVideo/${videoId}`,{userId: userData?._id});
      setLikes(response.data.likes);
    } catch (error) {}
  };



  const handleCardClick = () => {
    if (!userData) {
      navigate("/login");
    }
    return;
  };

  const handleHeartClick = (e) => {
    e.stopPropagation();
    userData && handleLike();
  };

  const formatDistanceDay = (date) => {
    try {
      const oneDay = 1000 * 3600 * 24;
      let parsedDate = new Date(date);
      if (isNaN(parsedDate)) {
        parsedDate = new Date(date.replace(/-/g, "/"));
      }

      const now = new Date();
      const nowUtc = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
      const parsedDateUtc = Date.UTC(
        parsedDate.getFullYear(),
        parsedDate.getMonth(),
        parsedDate.getDate()
      );

      const distance = nowUtc - parsedDateUtc;

      if (distance < oneDay && distance >= 0) {
        return "today";
      }

      let time = formatDistanceToNow(parsedDate, { addSuffix: true });
      let formattedDistance = time.replace(/in |about /g, "");
      return formattedDistance;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (videoLikes !== undefined) {
      setLikes(videoLikes);
    }
  }, [videoLikes]);

  useEffect(() => {
    const handleTouchStart = () => {
      if (video.current && video.current.paused) {
        video.current.play().catch((error) => {
          console.error("User interaction required to play video", error);
        });
      }
    };

    const handleTouchEnd = () => {
      if (video.current && !video.current.paused) {
        video.current.pause();
      }
    };

    if (video.current) {
      video.current.addEventListener("touchstart", handleTouchStart);
      video.current.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (video.current) {
        video.current.removeEventListener("touchstart", handleTouchStart);
        video.current.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, []);

  const [videoUrl, setVideoUrl] = useState("");
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);

  useEffect(() => {
    const handleProgress = () => {
      if (video.current) {
        const buffered = video.current.buffered;
        if (buffered.length > 0) {
          const bufferedEnd = buffered.end(buffered.length - 1);
          const duration = video.current.duration;
          if (duration > 0) {
            setBufferedPercentage((bufferedEnd / duration) * 100);
          }
        }
      }
    };

    const handleTimeUpdate = () => {
      if (video.current) {
        const currentTime = video.current.currentTime;
        const duration = video.current.duration;
        if (duration > 0) {
          setPlayedPercentage((currentTime / duration) * 100);
        }
      }
    };

    const debouncedProgress = debounce(handleProgress, 100);
    const debouncedTimeUpdate = debounce(handleTimeUpdate, 100);

    if (video.current) {
      video.current.addEventListener("progress", debouncedProgress);
      video.current.addEventListener("timeupdate", debouncedTimeUpdate);
    }

    return () => {
      if (video.current) {
        video.current.removeEventListener("progress", debouncedProgress);
        video.current.removeEventListener("timeupdate", debouncedTimeUpdate);
      }
    };
  }, []);

  const fetchVideoUrl = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/videos/video-url/${videoId}`
      );
      if (data && data.videoUrl) {
        // video.current.src = data.videoUrl;
        return data.videoUrl;
        setIsLoadingVideo(false);
      }
    } catch (error) {
      console.error("Error fetching video URL:", error);
    }
  };

  let pauseTimeout;
  let playTimeout;

  const handleMouseEnter = async () => {
    setIsHovered(true);
    setIsLoadingVideo(true);
    setCurrentPlayingVideo(index); // Set the current playing video

    const videoUrl = await fetchVideoUrl();

    if (videoUrl && video.current) {
      video.current.src = videoUrl;

      if (Hls.isSupported()) {
        const newHls = new Hls();
        newHls.loadSource(videoUrl);
        newHls.attachMedia(video.current);
        setHls(newHls);

        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.current.play().catch(console.error);
        });
      } else if (video.current.canPlayType("application/vnd.apple.mpegurl")) {
        video.current.play().catch(console.error);
      }
    }

    setIsLoadingVideo(false);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);

    if (video.current) {
      video.current.pause();
      video.current.src = ""; // Clear the video source to stop sound
    }
  };

  useEffect(() => {
    if (currentPlayingVideo !== index && video.current) {
      video.current.pause();
      video.current.src = ""; // Stop other videos when a new one is hovered
    }
  }, [currentPlayingVideo]);

  useEffect(() => {
    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [hls]);

  // const handleMouseEnter = () => {
  //   setIsHovered(true);

  //   console.log(videoPreview);

  //   if (video.current && video.current.paused) {
  //     video.current.src = videoPreview;
  //     video.current.currentTime = 0;
  //     video.current.play().catch((error) => {});
  //   }
  // };

  // useEffect(() => {
  //   // if (video.current) {
  //   video.current.pause();
  //   // }
  // }, [isHovered]);

  const handleLoadedMetadata = () => {
    if (video.current) {
      setDuration(video.current.duration);
    }
  };

  const handleVideoEnded = () => {
    setIsVideoEnded(true);
  };

  const LoadingIndicator = () => (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "10",
        pointerEvents: "none",
      }}
    >
      <CircularProgress />
    </div>
  );

  return (
    <div
      className={styles.card}
      ref={lastVideoElementRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles["media-container"]}>
        {isHovered && (
          <div
            id="mute"
            style={{
              position: "absolute",
              right: "10px",
              bottom: "10px",
              zIndex: "1000000",
              borderRadius: "5px",
              padding: "5px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            }}
            onClick={() => toggleMute()}
          >
            <img
              src={isMuted ? sound_off_icon : sound_icon}
              alt="sound"
              id="mute-icon"
              style={{
                height: "25px",
                width: "25px",
                minHeight: "25px",
                minWidth: "25px",
                maxHeight: "25px",
                maxWidth: "25px",
                cursor: "pointer",
              }}
            />
          </div>
        )}

        <img
          style={{
            display: !isHovered ? "block" : "none",
            maxHeight: 250,
            // objectFit: "cover",
          }}
          className="imgVideo"
          src={videoCapture}
          loading="lazy"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = logo;
          }}
          alt="Loading"
        />

        {isLoadingVideo && <LoadingIndicator />}
        <video
          style={{ display: isHovered ? "block" : "none" }}
          preload="none"
          alt="Card"
          className={styles["post-video"]}
          muted={isMuted}
          ref={video}
          onClick={() => openVideoDisplay(index)}
          onLoadedMetadata={handleLoadedMetadata}
          onEnded={handleVideoEnded}
          // onError={(e) => console.error("Video load error:", e)}
        ></video>

        {/* Buffering progress bar */}

        {isHovered && (
          <div
            className={styles["progress-bar-container"]}
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "5px",
              backgroundColor: "rgba(232, 103, 71, 0.2)",
              zIndex: "999999",
            }}
          >
            <div
              className={styles["buffered-bar"]}
              style={{
                width: `${bufferedPercentage}%`,
                height: "100%",
                backgroundColor: "rgba(232, 103, 71, 0.3)",
                position: "relative",
                borderRadius: "8px",
              }}
            >
              <div
                className={styles["played-bar"]}
                style={{
                  width: `${playedPercentage}%`,
                  height: "100%",
                  backgroundColor: "rgba(232, 103, 71, 0.8)",
                  position: "absolute",
                  borderRadius: "8px",
                }}
              />
            </div>
          </div>
        )}

        <div
          className={styles["like-button"]}
          onClick={handleHeartClick}
          style={{
            position: "absolute",
            left: "10px",
            top: "10px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            color: "#fff",
            padding: "5px 6px",
            borderRadius: "5px",
          }}
        >
          <img
            src={likes?.includes(userData?._id) ? redHeart : grayHeart}
            alt="like button"
            // className={styles["heart"]}
            style={{
              width: "20px",
              height: "20px",
              minWidth: "20px",
              minHeight: "20px",
            }}
          />
          <span style={{ color: "#fff", fontSize: "14px", fontWeight: "700" }}>
            {likes?.length}
          </span>
        </div>

        {isHovered && duration && (
          <div
            className={styles["video-duration"]}
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              color: "#fff",
              padding: "5px 6px",
              borderRadius: "5px",
              fontSize: "13px",
              fontWeight: "700",
              minHeight: "31px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {Math.floor(duration / 60)}:
            {("0" + Math.floor(duration % 60)).slice(-2)}
          </div>
        )}
      </div>
      <div className={styles["card-content"]}>
        <div className={styles["product-image"]}>
          {productImage ? (
            <Link to={`/product-details/${productId}`}>
              <img
                src={
                  productImage.includes("upload")
                    ? `${process.env.REACT_APP_API_URL}/${productImage}`
                    : productImage
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = robo;
                }}
                alt="product"
                style={{ objectFit: "cover" }}
              />
            </Link>
          ) : (
            <img src={robo} alt="product placeholder" />
          )}
        </div>

        <div style={{ width: "100%" }}>
          <div className={styles["card-details"]} onClick={handleCardClick}>
            <div className={styles["title-button-wrap"]}>
              <Link to={`/product-details/${productId}`} key={index}>
                <h2 className={styles["card-title"]}>
                  {productName?.length > 20
                    ? productName?.slice(0, 20) + "..."
                    : productName}
                </h2>
              </Link>
            </div>
          </div>
          <div className={styles["author"]}>
            <Link to={`/product-details/${productId}`} key={index}>
              <span>{plan}</span>
            </Link>
            <span>{formatDistanceDay(date)}</span>
            <p> {productOwner}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
