import React, { useEffect, useState, useRef } from "react";
import style from "./productDetails.module.scss";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import Pagination from "@mui/material/Pagination";
import { pin, pinned, robo } from "../../../assets/images";
import { useParams } from "react-router-dom";
import axios from "axios";
import ProductReviews from "./ProductReviews";
import { Helmet } from "react-helmet-async";
import Hls from "hls.js";
import { CircularProgress } from "@mui/material";

const MAX_VIDEOS_PER_PAGE = 9;

const ProductDetails = () => {
  const userData = JSON.parse(window.localStorage.getItem("user"));
  const productId = useParams();
  const [product, setProduct] = useState(null);
  const [ratings, setRatings] = useState([]);
  const [rates, setRates] = useState(0);
  const [pinVideo, setPinVideo] = useState(null);
  const [page, setPage] = useState(1);
  const [videos, setVideos] = useState(null);
  const [productCover, setProductCover] = useState(null);
  const [productCoverPath, setProductCoverPath] = useState("");
  const [loading, setLoading] = useState(true);
  const [ratingsId, setRatingsId] = useState(null);
  const videoRef = useRef(null);
  const [hls, setHls] = useState(null);
  const [value, setValue] = useState(0);

  // const fetchRatings = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/ratings/get-ratings`,
  //       { ratings: ratingsId }
  //     );
  //     if (response.data.length > 0) {
  //       let sum = 0;
  //       for (const rate of response.data) {
  //         sum += rate.value;
  //       }
  //       setRates(sum / response.data.length);
  //       setRatings(response.data);
  //     }
  //   } catch (error) {}
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const startIndex = (page - 1) * MAX_VIDEOS_PER_PAGE;
  const endIndex = startIndex + MAX_VIDEOS_PER_PAGE;

  useEffect(() => {
    const fetchData = async () => {
      if (userData != null) {
        try {
          const [productData, videoData] = await Promise.all([
            getBusinessOwnerProduct(),
            getBusinessOwnerProductVideos(),
          ]);

          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [productId]);

  const getBusinessOwnerProduct = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/products/${productId.id}`
      );
      const response = await res.json();
      setProduct(response);
      console.log(response);
      console.log("this is the user data");
      console.log(userData._id);
      console.log("this is the product data");
      console.log(response?.business_owner._id);

      setRatings(response.ratings);
      setRatingsId(productId.ratings);
      setPinVideo(response.pin_video);
      if (Hls.isSupported() && videoRef.current) {
        const newHls = new Hls();
        console.log("cond 3");
        console.log("response.pin_video", response.pin_video);
        newHls.loadSource(response.pin_video.videoURL);
        newHls.attachMedia(videoRef.current);
        setHls(newHls);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getBusinessOwnerProduct();
  }, [value, sessionStorage.getItem("pined-video")]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getBusinessOwnerProductVideos = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/videos/all-product-videos/${productId.id}`
      );
      const response = await res.json();
      setVideos(response);
    } catch (error) {}
  };

  const followProduct = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/add-follow`,
        { userId: userData?._id, productId: productId.id }
      );
    } catch (error) {}
  };

  const removeFollowProduct = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/remove-follow`,
        { userId: userData?._id, productId: productId.id }
      );
    } catch (error) {}
  };
  const handleFollow = async () => {
    if (product.followers.includes(userData?._id)) {
      await removeFollowProduct();
      getBusinessOwnerProduct();
    } else {
      await followProduct();
      getBusinessOwnerProduct();
    }
  };

  const handleCoverChange = (event) => {
    setProductCoverPath(event.target.files[0]);
    setProductCover(URL.createObjectURL(event.target.files[0]));
    setProduct({
      ...product,
      cover_image: URL.createObjectURL(event.target.files[0]),
    });
  };

  useEffect(() => {
    updateProfileCover();
  }, [productCoverPath]);

  const updateProfileCover = async () => {
    const formData = new FormData();
    formData.append("cover", productCoverPath);

    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/products/update-cover-profile/${productId.id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const response = res.data;
    if (response.user)
      window.localStorage.setItem("user", JSON.stringify(response.user));
  };

  const getBaseUrl = (url) => {
    const match = url.match(/^(https?:\/\/)?([^\/]+)/i);
    return match ? match[0] : url;
  };

  function formatNumber(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num.toString();
  }

  return (
    <>
      <Helmet>
        <title>{`${
          product?.name ? product.name : "Product"
        } | Demo on Recolyse`}</title>
        <meta
          name="description"
          content={`Explore ${product?.name} in detail on Recolyse. Watch demos, understand key features, and see how it can fit your needs.`}
        />
      </Helmet>
      <div className={style.details}>
        {loading ? (
          <div className={style.loading}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <>
            <div className={`${style["details"]}`}>
              <div className={`${style["header"]}`}>
                <div className={`${style["cover-box"]}`}>
                  {productCover || product?.cover_image ? (
                    <img
                      src={
                        product?.cover_image?.includes("upload")
                          ? `${process.env.REACT_APP_API_URL}/${product?.cover_image}`
                          : product?.cover_image
                      }
                    />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                    ></svg>
                  )}
                  {product?.business_owner._id == userData._id && (
                    <>
                      <input
                        type="file"
                        className={`${style["input-img"]}`}
                        id="coverInput"
                        onChange={handleCoverChange}
                      />
                      <label
                        htmlFor="coverInput"
                        className={`${style["label-img"]}`}
                      >
                        <span>Edit Cover</span>
                      </label>
                    </>
                  )}
                </div>
                <div className={`${style["product-details"]}`}>
                  <div className={`${style["info-left"]}`}>
                    <img
                      src={
                        product?.image.includes("upload")
                          ? `${process.env.REACT_APP_API_URL}/${product.image}`
                          : product?.image
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = robo;
                      }}
                      alt="img"
                    />
                    <div className={`${style["product-info"]}`}>
                      <span className={`${style["product-name"]}`}>
                        {product?.name}
                      </span>
                      <span className={`${style["product-email"]}`}>
                        {/* <a
                        href={product?.website_link ?? "https://example.com"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getBaseUrl(
                          product?.website_link ?? "https://example.com"
                        )}
                      </a> */}
                        {formatNumber(videos?.length || 0) + " Videos"}
                      </span>
                    </div>
                  </div>
                  <div className={`${style["info-right"]}`}>
                    {product?.business_owner._id != userData._id && (
                      <button
                        className={`${style["follow"]}`}
                        onClick={handleFollow}
                      >
                        {product?.followers.includes(userData?._id)
                          ? "Followed"
                          : "Follow"}
                      </button>
                    )}

                    <a
                      href={
                        product?.website_link &&
                        (!product.website_link.startsWith("http://") &&
                        !product.website_link.startsWith("https://")
                          ? "http://" + product.website_link
                          : product.website_link)
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`${style["website"]}`}
                    >
                      Visit Website
                    </a>
                  </div>
                </div>
              </div>

              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Basic tabs"
                defaultValue={0}
                className={`${style["tabs"]}`}
                sx={{
                  backgroundColor: "transparent !important",
                  margin: "50px 40px 30px 60px",
                }}
              >
                <TabList>
                  <Tab className="tab">Videos</Tab>
                  <Tab className="tab">Review</Tab>
                </TabList>
                <TabPanel value={0}>
                  <div
                    className={`${style["video-container"]}`}
                    style={
                      pinVideo
                        ? {}
                        : { display: "flex", justifyContent: "center" }
                    }
                  >
                    {pinVideo ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "24px",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          marginTop: "30px",
                        }}
                      >
                        <video
                          ref={videoRef}
                          key={pinVideo?._id}
                          style={{
                            width: "400px",
                            padding: "12px",
                            borderRadius: "13px",
                          }}
                          poster={pinVideo?.capture}
                          controls
                          className={`${style["video"]}`}
                        >
                          Your browser does not support HTML video.
                        </video>
                        <div className={`${style["top-video-info"]}`}>
                          <span className={`${style["desc"]}`}>
                            {pinVideo?.description}
                          </span>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h5 className={`${style["definition-title"]}`}>
                              What is <span>{product?.name}</span>
                            </h5>
                            <div className={`${style["likes"]}`}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="18"
                                viewBox="0 0 20 18"
                                fill="none"
                              >
                                <path
                                  d="M14.44 0C12.63 0 11.01 0.889888 10 2.25506C8.99 0.889888 7.37 0 5.56 0C2.49 0 0 2.52809 0 5.65281C0 6.85618 0.19 7.96854 0.52 9C2.1 14.0562 6.97 17.0798 9.38 17.909C9.72 18.0303 10.28 18.0303 10.62 17.909C13.03 17.0798 17.9 14.0562 19.48 9C19.81 7.96854 20 6.85618 20 5.65281C20 2.52809 17.51 0 14.44 0Z"
                                  fill="#FF6A55"
                                />
                              </svg>
                              <span>+{pinVideo?.likes.length}</span>
                            </div>
                          </div>

                          <p className={`${style["definition"]}`}>
                            {product?.description}
                          </p>

                          <span className={`${style["author"]}`}>
                            {"By " +
                              pinVideo?.product?.business_owner.firstName +
                              " " +
                              pinVideo?.product?.business_owner.lastName}
                          </span>

                          <h5 className={`${style["definition-title"]}`}>
                            <span>Categories:</span>
                          </h5>

                          <div
                            style={{
                              display: "flex",
                              gap: "16px",
                              flexWrap: "wrap",
                            }}
                          >
                            {product?.categories.map((category, index) => {
                              return (
                                <span
                                  key={index}
                                  className={`${style["product-type"]}`}
                                >
                                  {category}
                                </span>
                              );
                            })}
                          </div>

                          {/* <div className={`${style["screenshots"]}`}>
                            {product?.pictures.map((picture, index) => {
                              return (
                                <img
                                  key={index}
                                  src={
                                    picture.includes("upload")
                                      ? `${process.env.REACT_APP_API_URL}/${picture}`
                                      : picture
                                  }
                                  alt=""
                                />
                              );
                            })}
                          </div> */}
                        </div>
                      </div>
                    ) : (
                      <div className={`${style["create"]}`}>
                        {/* <ModalContent /> */}
                        <span>No Videos yet</span>
                      </div>
                    )}
                  </div>
                  <div className={`${style["videos"]}`}>
                    {Array.isArray(videos) &&
                      videos?.length > 0 &&
                      videos?.map((video) => (
                        <VideoContainer
                          key={video._id}
                          product={product}
                          setProduct={setProduct}
                          video={video}
                          pinVideoId={pinVideo?._id}
                          setPinVideo={setPinVideo}
                        />
                      ))}
                  </div>

                  <div className={`${style["pagination"]}`}>
                    <Pagination
                      count={Math.ceil(videos?.length / MAX_VIDEOS_PER_PAGE)}
                      page={page}
                      onChange={handlePageChange}
                      prevbuttontext="PREV"
                      nextbuttontext="NEXT"
                      className="pagi"
                    />
                  </div>
                </TabPanel>

                <TabPanel value={1}>
                  {
                    <ProductReviews
                      key={product?._id}
                      product={product}
                      ratings={ratings}
                      setParentRatings={setRatingsId}
                    />
                  }
                </TabPanel>
              </Tabs>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ProductDetails;

export const VideoContainer = ({
  product,
  setProduct,
  video,
  pinVideoId,
  setPinVideo,
}) => {
  const videoRef = useRef(null);
  const hlsRef = useRef(null);

  const userData = JSON.parse(window.localStorage.getItem("user"));

  const updatePinVideo = async () => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/products/update/${product._id}`,
        {
          pin_video: video._id,
        }
      );
      const response = res.data;
      return response;
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };
  const handlePinVideo = async (videoSelected) => {
    try {
      const response = await updatePinVideo();

      if (response) {
        setProduct(response);

        setPinVideo(videoSelected);
        sessionStorage.setItem("pined-video", JSON.stringify(videoSelected));

        console.log("Pin video updated successfully.");
        console.log(videoSelected);
      }
    } catch (error) {
      console.error("Error pinning the video:", error);
    }
  };

  useEffect(() => {
    if (Hls.isSupported() && videoRef.current) {
      if (hlsRef.current) {
        hlsRef.current.destroy(); // Clean up the previous instance
      }
      const newHls = new Hls();
      hlsRef.current = newHls; // Store the instance

      newHls.loadSource(video.videoURL);
      newHls.attachMedia(videoRef.current);
    } else if (videoRef.current) {
      // Fallback for browsers that support native HLS
      videoRef.current.src = video.videoURL;
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy(); // Clean up when the component unmounts
      }
    };
  }, [video.videoURL, pinVideoId]);

  return (
    <div className={`${style["video"]}`} style={{ maxWidth: "400px" }}>
      <div
        style={{
          position: "relative",
          maxWidth: "400px",
          padding: "12px",
        }}
      >
        {userData?._id === video?.product?.business_owner._id && (
          <>
            {video._id === pinVideoId ? (
              <img
                style={{
                  position: "absolute",
                  top: "5%",
                  right: "2%",
                  zIndex: "999",
                  padding: "2px",
                  width: "50px",
                  height: "50px",
                  cursor: "pointer",
                }}
                src={pin}
              />
            ) : (
              <>
                <img
                  style={{
                    position: "absolute",
                    top: "5%",
                    right: "2%",
                    zIndex: "999",
                    padding: "2px",
                    width: "50px",
                    height: "50px",
                    cursor: "pointer",
                  }}
                  src={pinned}
                  onClick={() => handlePinVideo(video)}
                />
              </>
            )}
          </>
        )}
        <video
          ref={videoRef}
          key={video.videoId}
          poster={video.capture}
          controls
          className={`${style["video-player"]}`}
        >
          `${process.env.REACT_APP_API_URL}/${product?.cover_image}`
          {/* <source src={video.videoURL}
          
          /> */}
          Your browser does not support HTML video.
        </video>
      </div>
      <div className={`${style["video-info"]}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <img
            src={
              product?.image.includes("upload")
                ? `${process.env.REACT_APP_API_URL}/${product.image}`
                : product?.image
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = robo;
            }}
            alt="logo"
          />
          <div className={`${style["video-info-right"]}`}>
            <div className={`${style["video-info-right-top"]}`}>
              <span>{video.caption ? video.caption : "..."}</span>
              <div className={`${style["likes"]}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="16"
                  viewBox="0 0 20 18"
                  fill="none"
                >
                  <path
                    d="M14.44 0C12.63 0 11.01 0.889888 10 2.25506C8.99 0.889888 7.37 0 5.56 0C2.49 0 0 2.52809 0 5.65281C0 6.85618 0.19 7.96854 0.52 9C2.1 14.0562 6.97 17.0798 9.38 17.909C9.72 18.0303 10.28 18.0303 10.62 17.909C13.03 17.0798 17.9 14.0562 19.48 9C19.81 7.96854 20 6.85618 20 5.65281C20 2.52809 17.51 0 14.44 0Z"
                    fill="#FF6A55"
                  />
                </svg>
                <span>+{video?.likes.length}</span>
              </div>
            </div>
          </div>
        </div>
        <span className={`${style["author"]}`}>
          {" "}
          <span
            style={{
              backgroundColor: "#E86747",
              color: "white",
              fontWeight: "bold",
              padding: "3px 5px",
              borderRadius: "8px",
              textTransform: "uppercase",
              fontSize: "12px",
            }}
          >
            {video.category}
          </span>{" "}
          <span>
            {"By "}
            {" " +
              video.product?.business_owner.firstName +
              " " +
              video.product?.business_owner.lastName}
          </span>
        </span>
      </div>
    </div>
  );
};
