import React, { useState, useEffect } from "react";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  google,
  red_circle,
  img_right,
  ellipse_signup,
  login_ellipse,
  logo,
} from "../../../assets/images";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PrimaryButton from "../../../components/primary-button/PrimaryButton";
// import style from "../login-page/login.module.scss";
import {
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import style from "./signup.module.scss";
import { useUser } from "../../../state/UserContext";
import { Helmet } from "react-helmet-async";

const SignupPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("Male");
  const [role, setRole] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [formValid, setFormValid] = useState(false);
  const { setUser } = useUser();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#E86747",
      },
    },
  });

  useEffect(() => {
    const getUserGeolocationDetails = async () => {
      try {
        const response = await fetch(
          "https://geolocation-db.com/json/0daad5e0-82e7-11ee-92e0-f5d620c7dcb4"
        );
        const data = await response.json();
        setCountry(data.country_name);
      } catch (error) {
        console.error("Error fetching geolocation details:", error);
      }
    };

    getUserGeolocationDetails();
  }, []);

  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const hasNonAlphas = /\W/.test(password);
    const isValidLength = password.length >= 8;
    return (
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasNonAlphas &&
      isValidLength
    );
  };

  useEffect(() => {
    setFormValid(
      firstName &&
        lastName &&
        email &&
        validateEmail(email) &&
        gender &&
        role &&
        country &&
        birthday &&
        password &&
        password === confirmPassword
    );
  }, [
    firstName,
    lastName,
    email,
    gender,
    role,
    country,
    birthday,
    password,
    confirmPassword,
  ]);

  const handleDateChange = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setBirthday(formattedDate);
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const passwordsMatch = password === confirmPassword;
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleRegistration = async (event) => {
    event.preventDefault();

    if (!formValid) {
      setSnackbarMessage("Please fill in all fields correctly.");
      setSnackbarOpen(true);
      return;
    }

    const userData = {
      firstName,
      lastName,
      email,
      gender,
      role,
      country,
      birthday,
      password,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 201) {
        setSnackbarMessage("Registration successful!");
        setSnackbarOpen(true);
        const { user, token } = response.data;
        setUser(response.data);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);
        localStorage.setItem("isBusinessOwner", false);
        window.location.href = "/";
      } else {
        console.error("Registration failed:", response.data);
        setSnackbarMessage("Registration failed. Please try again.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      const errorMsg =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Registration failed. Please try again.";
      setSnackbarMessage(errorMsg);
      setSnackbarOpen(true);
    }
  };
  const handleAuthGoogle = async () => {
    try {
      window.open(
        `${process.env.REACT_APP_API_URL}/auth/google/callback`,
        "_self"
      );
    } catch (error) {
      // console.log("errrrrrrror : ",error.message);
    }
  };
  return (
    <>
      <Helmet>
        <title>
          Sign In to Recolyse | Access AI-Powered Solutions’ Recommendations
        </title>
        <meta
          name="description"
          content="Sign in or create an account on Recolyse to start exploring AI-powered product recommendations from innovating startups."
        />
      </Helmet>
      <div className={style["signup-page"]}>
        <div>
          <img
            src={login_ellipse}
            alt="ellipse"
            style={{ position: "absolute", bottom: "0", left: "0" }}
          />
          <img
            src={login_ellipse}
            alt="ellipse"
            style={{
              position: "absolute",
              right: "0",
              top: "0",
              transform: "rotate(180deg)",
            }}
          />
        </div>
        <div className={style["signup-container"]}>
          {/* Left Image */}
          <div className={style["signup-left"]}>
            <img
              src={img_right}
              alt="background left"
              className={style["signup-img"]}
            />
          </div>
          <div className={style["signup-right"]}>
            {/* Logo */}

            <div className={style["logo"]}>
              <img onClick={() => navigate("/")} src={logo} alt="logo" />
            </div>
            <section className={style["signup-right-section"]}>
              <div className={style["right-content"]}>
                <h1>Create account!</h1>
                {/* Form */}
                <form onSubmit={handleRegistration}>
                  <ThemeProvider theme={theme}>
                    <div className={style["form-content"]}>
                      <div className={style["form-row-1"]}>
                        {/* FirstName */}
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <label className={style["input-label"]}>
                            First Name
                          </label>
                          <TextField
                            id="firstName"
                            variant="outlined"
                            color="primary"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className={style["form-input"]}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <label className={style["input-label"]}>
                            First Name
                          </label>
                          <TextField
                            id="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            variant="outlined"
                            color="primary"
                            className={style["form-input"]}
                          />
                        </div>
                      </div>
                      <div className={style["form-row"]}>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <label className={style["input-label"]}>Gender</label>
                          <FormControl
                            className={style["form-input"]}
                            variant="standard"
                          >
                            <Select
                              labelId="gender-label"
                              id="gender"
                              variant="outlined"
                              color="primary"
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <MenuItem value="male">Male</MenuItem>
                              <MenuItem value="female">Female</MenuItem>
                            </Select>
                          </FormControl>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <label className={style["input-label"]}>Job</label>
                          <TextField
                            id="role"
                            color="primary"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            variant="outlined"
                            className={style["form-input"]}
                          />
                        </div>
                      </div>
                      <div className={style["form-row"]}>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <label className={style["input-label"]}>
                            Birthdate
                          </label>
                          <div className={style["form-input"]}>
                            {/**variant="standard" */}

                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              className=""
                            >
                              <DemoItem className="date-picker">
                                <DatePicker
                                  views={["year", "month", "day"]}
                                  onChange={handleDateChange}
                                />
                              </DemoItem>
                            </LocalizationProvider>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <label className={style["input-label"]}>
                            Country
                          </label>

                          <TextField
                            id="country"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            variant="outlined"
                            color="primary"
                            className={style["form-input"]}
                          />
                        </div>
                      </div>

                      <div className={style["form-row"]}>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <label className={style["input-label"]}>Email</label>

                          <TextField
                            id="outlined-basic"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined"
                            color="primary"
                            sx={{ width: "100%" }}
                            type="email"
                            required
                          />
                        </div>
                      </div>

                      <div className={style["form-row"]}>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <label className={style["input-label"]}>
                            Password
                          </label>
                          <TextField
                            id="standard-adornment-password"
                            variant="outlined"
                            color="primary"
                            className={style["form-input"]}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label={
                                      showPassword
                                        ? "Hide password"
                                        : "Show password"
                                    }
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <label className={style["input-label"]}>
                            Confirm Password
                          </label>

                          <TextField
                            id="outlined-adornment-password"
                            className={style["form-input"]}
                            color="primary"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label={
                                      showPassword
                                        ? "Hide password"
                                        : "Show password"
                                    }
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                      {passwordsMatch ? null : (
                        <p style={{ color: "red" }}>Passwords do not match.</p>
                      )}
                      <div style={{ marginTop: "30px" }}>
                        <PrimaryButton text="Register" full="w-full" />
                      </div>
                    </div>
                  </ThemeProvider>
                </form>
                {/* Login buttons */}
                <div className={style["google"]}>
                  {/* Google Login Button */}
                  <button onClick={handleAuthGoogle}>
                    <img src={google} alt="google" />
                    <span style={{ fontSize: "14px", fontWeight: "500" }}>
                      <span
                        style={{ display: "none" }}
                        className={style["register-text"]}
                      >
                        Register with{" "}
                      </span>
                      Google
                    </span>
                  </button>
                </div>
                {/* Go to Sign Up */}
                <div className={style["have-account"]}>
                  <p>Already have an account ?</p>
                  <button onClick={() => navigate("/login")}>Login</button>
                </div>
              </div>
            </section>
            <div></div>
          </div>
        </div>

        <Snackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity="error">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default SignupPage;
